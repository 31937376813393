var rtscript = document.createElement('script');
var recruitee_div = document.getElementById('recruitee-careers');
rtscript.type = 'text/javascript';
rtscript.onload = function() { var widget = new RTWidget({

    "companies": [

        44522

    ],

    "detailsMode": "popup",

    "language": "en",

    "departmentsFilter": [],

    "themeVars": {

        "primary": "#009de0",

        "secondary": "#244a8f",

        "text": "#353535",

        "textDark": "#000",

        "fontFamily": "\"Helvetica Neue\", Helvetica, Arial, \"Lucida Grande\", sans-serif;",

        "baseFontSize": "16px"

    },

    "flags": {

        "showLocation": true,

        "showCountry": true,

        "showCity": true,

        "groupByLocation": false,

        "groupByDepartment": false,

        "groupByCompany": false

    },

}); };
rtscript.src = 'https://d10zminp1cyta8.cloudfront.net/widget.js';

if (recruitee_div) {
    recruitee_div.append(rtscript);
}