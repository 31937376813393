micronit.controller('ShareCtrl', ['$scope', 'ngDialog', '$window', '$http', function($scope, ngDialog, $window, $http) {
    $scope.pending = false;

    $scope.shareForm = {
        "email": "",
        "subject": "",
        "message": "",
        "hp": "",
        "le": ""
    };

    $scope.errorMessage = '';
    $scope.mailDomainError = false;
    $scope.pageTitle = '';
    $scope.openShareDialog = function(title){
        $scope.pageTitle="";
        $scope.pageTitle=title;

        $scope.shareForm.email="";
        $scope.shareForm.message = 'Someone wants to share the following article with you: ' + $window.location.href;
        ngDialog.open({
            template: 'shareArticleTemplate.html',
            className: 'ngdialog-theme-default',
            scope: $scope
        });
    };

    $scope.submitShareForm = function(restUri){
        var payload = new FormData();
        payload.append("subject", $scope.shareForm.subject);
        payload.append("email", $scope.shareForm.email);
        payload.append("message", $scope.shareForm.message);
        payload.append("hp", $scope.shareForm.hp);
        payload.append("le", $scope.shareForm.le);

        $scope.pending = true;


        $http({
            url: restUri,
            method: 'POST',
            data: payload,
            headers: { 'Content-Type': undefined},
            transformRequest: angular.identity
        }).then(function(success){
            $scope.pending = false;
            $scope.mailDomainError = false;
            ngDialog.closeAll();
            ngDialog.open({
                template: 'shareArticleTemplateSuccess.html',
                className: 'ngdialog-theme-default',
                scope: $scope
            });

        }).catch(function(error){
            $scope.mailDomainError = true;
            $scope.pending = false;

            console.log('error', error);
        });
    };

}]);
