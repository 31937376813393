micronit.controller('MapCtrl', ['$scope','NgMap', '$window', function($scope, NgMap, $window) {
    $scope.googleMapsUrl = 'https://maps.google.com/maps/api/js?key=AIzaSyAnb12HVLayOXBQXt694h186rW83X4IhBg';

    $scope.redrawMap = function(id){
        NgMap.getMap({id: id }).then(function(map) {
            google.maps.event.trigger(map, 'resize');
        });
    };

    $scope.openLocationGoogleMaps = function(url){
        // console.log(locationString);
        // var commaPos = locationString.indexOf(',');
        // var coordinatesLat = parseFloat(locationString.substring(0, commaPos));
        // var coordinatesLong = parseFloat(locationString.substring(commaPos + 1, locationString.length));
        // console.log(coordinatesLat);
        // console.log(coordinatesLong);
        // var url = 'http://maps.google.com/maps?&z=10&q='+coordinatesLat+'+'+coordinatesLong+'&ll='+coordinatesLat+'+'+coordinatesLong;
        $window.open(url);
    };

}]);
