$(document).ready(function () {
    $(function () {
        $('.navigation-menu-container ul li').children('div').css({
            display: "none",
            opacity: 1,
            height: "auto"
        });

        $('.navigation-menu-container ul li').mouseover(function () {
            $(this).children('div').stop(true, false, true).slideDown(300, function () {
                $(this).css({
                    display: "flex",
                    height: "auto"
                });
            });
            $(this).children('a').addClass('navigation-menu-link-hover');
        });

        $('.navigation-menu-container ul li').mouseleave(function () {
            if ($(this).children('div').prevObject[0].children.length > 1) {
                $(this).children('div').stop(true, false, true).slideUp({
                        duration: 300, complete:
                            function () {
                                if (!$("body").hasClass("home")) {
                                    if ($(window).scrollTop() === 0 && $('.navigation-menu-item:hover').length === 0) {
                                        $('.breadcrumb').stop(true, true, true).slideDown(200, function (){
                                            $('.breadcrumb').addClass('breadcrumb-active');
                                        });
                                    } else if ($(window).scrollTop() !== 0 && $('.navigation-menu-item:hover').length === 0) {
                                        $('.breadcrumb').stop(true, true, true).slideDown(200, function (){
                                            $('.breadcrumb').addClass('breadcrumb');
                                        });
                                    }
                                }
                            }
                    }
                );
            } else if ($(this).children('div').prevObject[0].children[0].children[0] !== undefined && $(this).children('div').prevObject[0].children[0].children[0].innerHTML === 'Contact') {
                if ($(window).scrollTop() === 0) {
                    $('.breadcrumb').stop(false, true, false).slideDown({
                        duration: 200, complete: function () {
                            $('.breadcrumb').addClass('breadcrumb-active');
                        }
                    });
                } else {
                    $('.breadcrumb').slideDown({
                        duration: 200, complete: function () {
                            $('.breadcrumb').addClass('breadcrumb');
                        }
                    });
                }
            }
            $(this).children('a').removeClass('navigation-menu-link-hover');
        });

        if (!$("body").hasClass("home")) {
            if ($(this).scrollTop() === 0) {
                $('.breadcrumb').addClass('breadcrumb-active');
                $('.breadcrumb-container').addClass('breadcrumb-container-fade');
            } else {
                $('.breadcrumb').removeClass('breadcrumb-active');
                $('.breadcrumb-container').removeClass('breadcrumb-container-fade');
            }

            $(window).scroll(function () {
                if ($(this).scrollTop() === 0) {
                    $('.breadcrumb').addClass('breadcrumb-active');
                    $('.breadcrumb-container').addClass('breadcrumb-container-fade');
                } else {
                    $('.breadcrumb').removeClass('breadcrumb-active');
                    $('.breadcrumb-container').removeClass('breadcrumb-container-fade');
                }
            });

            $('.breadcrumb').mouseover(function () {
                $('.breadcrumb').addClass('breadcrumb-active');
                $('.breadcrumb-container').addClass('breadcrumb-container-fade');
            });

            $('.breadcrumb').mouseleave(function () {
                if ($(window).scrollTop() !== 0) {
                    $('.breadcrumb').removeClass('breadcrumb-active');
                    $('.breadcrumb-container').removeClass('breadcrumb-container-fade');
                }
            });
        }

        $('.navigation-menu').mouseover(function (event) {
            $('.breadcrumb').stop(true, true, true).slideUp({
                duration: 300, complete: function () {
                    if (!$("body").hasClass("home")) {
                        if ($(window).scrollTop() === 0) {
                            $('.breadcrumb').removeClass('breadcrumb-active');
                        }
                    }
                }
            });
        });

        $('.navigation-hover').mouseleave(function (event) {
            if (!$("body").hasClass("home")) {
                if ($(window).scrollTop() === 0) {
                    $('.breadcrumb').stop(true, true, true).slideDown({
                        duration: 200, complete: function () {
                            $('.breadcrumb').addClass('breadcrumb-active');

                        }
                    });
                } else {
                    $('.breadcrumb').stop(true, true, true).slideDown({
                        duration: 200, complete: function () {
                            $('.breadcrumb').addClass('breadcrumb');
                        }
                    });
                }
            }
        });

        $('.navigation-actions-search-bar-container').mouseover(function (event) {
            $('.breadcrumb').stop(true, true, true).slideUp(300, function(){
                if (!$("body").hasClass("home")) {
                    if ($(window).scrollTop() === 0) {
                        $('.breadcrumb').removeClass('breadcrumb-active');
                    }
                }
            });
        });
    });

    var cookiebarButton = document.querySelector('.cookie-bar-accept');
    var cookiebar = document.querySelector('.cookie-bar');
    var cookiebarLS = getCookie('cookie-bar');
    var footer = document.querySelector('.footer');

    if (cookiebar && footer) {
        if (cookiebarLS === '') {
            $('.cookie-bar').removeClass('cookie-bar-hidden');
            $('.footer').addClass('has-cookie');
        } else {
            setCookie('cookie-bar', true, 7);
        }

        cookiebarButton.addEventListener('click', function () {
            setCookie('cookie-bar', true, 7);
            $('.cookie-bar').addClass('cookie-bar-hidden');
            $('.footer').removeClass('has-cookie');
        }, false);

    }

    function getCookie(cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }


    function setCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }


});
