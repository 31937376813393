micronit.controller('ContactFormCtrl', ['$scope', '$http', 'ngDialog', function($scope, $http, ngDialog) {
    $scope.previewPDF = [];
    $scope.showSpinner = false;
    $scope.fileError = false;
    $scope.invalidFiles = null;
    $scope.pending = false;

    $scope.form = {
        company: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        address: "",
        city: "",
        country: "",
        subject: "",
        files: [],
        message: "",
        hp: "",
        le: ""
    };
    $scope.fileData = null;

    $scope.submitForm = function(restUri){
        var payload = new FormData();
        payload.append("company", $scope.form.company);
        payload.append("firstName", $scope.form.firstName);
        payload.append("lastName", $scope.form.lastName);
        payload.append("email", $scope.form.email);
        payload.append("phone", $scope.form.phone);
        payload.append("address", $scope.form.address);
        payload.append("city", $scope.form.city);
        payload.append("country", $scope.form.country);
        payload.append("subject", $scope.form.subject || 'Contact form');
        if ($scope.form.files.length>0){
        for (var i=0; i<$scope.form.files.length; i++){
            if($scope.form.files[i]!==null){}
            var fileName = 'file'+i;
            var file = $scope.form.files[i];
            payload.append(fileName, file);
        }
        }
        payload.append("message", $scope.form.message);
        payload.append("hp", $scope.form.hp);
        payload.append("le", $scope.form.le);
        $scope.pending = true;




        ngDialog.open({
            template: 'contactFormSuccess.html',
            className: 'ngdialog-theme-default',
            scope: $scope,
            preCloseCallback: function() {
                $scope.invalidFiles = null;
                $scope.previewPDF = [];
                $scope.form = {
                    company: "",
                    firstName: "",
                    lastName: "",
                    email: "",
                    phone: "",
                    address: "",
                    city: "",
                    country: "",
                    subject: "",
                    files: [],
                    message: "",
                    hp: "",
                    le: ""
                };
                $scope.contactForm.$setPristine();
            }
        });


        $http({
            url: restUri,
            method: 'POST',
            data: payload,
            headers: { 'Content-Type': undefined},
            transformRequest: angular.identity
        }).then(function(success){
            $scope.pending = false;
        }).catch(function(error){
            console.log('error', error);
            $scope.pending = false;
        });
    };

        $scope.upload = function (files, invalidFiles) {
            $scope.showSpinner = true;
            $scope.form.files = files;
            $scope.previewPDF = [];
            angular.forEach(files, function(file){
                if (file.type==='application/pdf' && file.size<3500000){
                    var fileReader = new FileReader();
                    fileReader.readAsDataURL(file);
                    fileReader.onload = function (e) {
                        $scope.$apply(function () {
                            $scope.previewPDF.push({data: e.target.result, name: file.name});
                        });
                    };
                }
            });
            $scope.showSpinner = false;

        };

    $scope.removeFile = function(file) {
        var index = $scope.form.files.indexOf(file);
        console.log(index);
        $scope.form.files.splice(index, 1);
    };

    $scope.pdfToBase64 = function(data){
        var fileData = null;
        var fileReader = new FileReader();
        fileReader.readAsDataURL(data);
        fileReader.onload = function (e) {
            fileData =  e.target.result;
        };
        return fileData;
    };

    $scope.getPdfPreview = function(pdfData){
        var fileData = null;
        var fileReader = new FileReader();
        fileReader.readAsDataURL(pdfData);
        fileReader.onload = function (e) {
            fileData =  e.target.result;
        };
        return fileData;
    };

    $scope.toBase64 = function(data){
        var fileReader = new FileReader();
        fileReader.readAsDataURL(data);
        fileReader.onload = function (e) {
            $scope.form.file = e.target.result;
        };
    };

    $scope.uploadSettings = {
        validate: {
            size: {
                max: '30MB'
            }
            },
        pattern: "image/*,video/*,application/pdf,.zip,.doc,.docx"
    };
}]);
