var micronit = angular.module('micronit',[
    'ngAnimate',
    'truncate',
    'duScroll',
    'ngFileUpload',
    'ngDialog',
    'ngMap',
    'ngSanitize',
    'ngResource'
]);

micronit.controller('MainCtrl', ['$scope', '$interpolate', '$window', '$document', '$timeout', function($scope, $interpolate, $window, $document, $timeout) {
    $scope.init = false;

    // $timeout(function(){
    $scope.init = true;
    // });

    $scope.selectedMenuItem = null;
    $scope.selectMenuItem = function(index){
        $scope.selectedMenuItem = index;
    };
    $scope.toggleSearch = function(){
        $scope.showSearch=!$scope.showSearch;
        $scope.focusSearch=!$scope.focusSearch;
    };

    $scope.toggleMobileSearch = function(){
        $scope.showMobileSearch = !$scope.showMobileSearch;
        $scope.focusMobileSearch = !$scope.focusMobileSearch;
        if (!$scope.showMobileSearch){
            $scope.searchMobileString='';
        }
    };

    $scope.focusSearch = false;
    $scope.focusMobileSearch = false;
    $scope.showSearch = false;
    $scope.showMobileSearch = false;
    $scope.showMobileMenu = false;
    $scope.searchMobileString = '';
    $scope.searchString = '';

    $scope.clearSearch = function(){
        if($scope.searchString===""){
            $scope.toggleSearch();
        }
        $scope.searchString = "";
    };
    $scope.clearMobileSearch = function(){
        if($scope.searchMobileString===""){
            $scope.toggleMobileSearch();
        }
        $scope.searchMobileString = "";
    };

    $scope.getVideo = function( url ){
        var youtubeVideoId = url.match(/(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/);
        var vimeoVideoId = url.match(/https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/);

        if(youtubeVideoId !== null) {
            return {
                "type": "youtube",
                "id": youtubeVideoId[1]
            };
        }
        if(vimeoVideoId !== null) {
            return {
                "type": "vimeo",
                "id": vimeoVideoId[3]
            };
        }
        if (youtubeVideoId === null && vimeoVideoId===null){
            return {
                "type": "invalid",
                "id": "Video url is not valid"
            };
        }
    };


    $scope.toggleMobileMenu = function(){
        $scope.showMobileMenu=!$scope.showMobileMenu;
        if($scope.showMobileMenu){
            $document.scrollTopAnimated(0, 500);
        }
    };

    $scope.mobileMenu = {};
    $scope.initMobileMenu = false;

    $scope.setMenu = function(menuItem){
        $scope.mobileMenu[menuItem] = false;
    };

    $scope.showHideSubMenu = function(menuItem){
        if ($scope.mobileMenu[menuItem]){
            $scope.mobileMenu[menuItem] = false;
        } else {
            angular.forEach($scope.mobileMenu, function(val, key) {
                $scope.mobileMenu[key] = false;
            });
            $scope.mobileMenu[menuItem] = true;
        }
    };


    $scope.getNumberOfWords= function(){
        return 55;
    };

    $scope.getNumberOfServiceWords= function(){
        if ($window.innerWidth <= 768){
            return 15;
        } else {
            return 25;
        }
    };


    $scope.getNumberOfNewsItemWords= function(){
        if ($window.innerWidth <= 768){
            return 20;
        } else {
            return 30;
        }
    };

}]);



micronit.directive('escKey', function () {
    return function (scope, element, attrs) {
        element.bind('keydown keypress', function (event) {
            if(event.which === 27) { // 27 = esc key
                scope.$apply(function (){
                    scope.$eval(attrs.escKey);
                });

                event.preventDefault();
            }
        });
    };
});

micronit.directive('focusMe', ['$timeout', '$parse', function($timeout, $parse) {
    return {
        //scope: true,   // optionally create a child scope
        link: function(scope, element, attrs) {
            var model = $parse(attrs.focusMe);
            scope.$watch(model, function(value) {
                if(value === true) {
                    $timeout(function() {
                        element[0].focus();
                    });
                }
            });
            // element.bind('blur', function() {
            //     scope.$apply(model.assign(scope, false));
            // });
        }
    };
}]);

micronit.directive('collapse', [function () {
    return {
        restrict: 'A',

        link: function ($scope, ngElement, attributes) {
            var element = ngElement[0];
            var init = false;
            $scope.$watch(attributes.collapse, function (collapse) {
                var newHeight = collapse ? 0 : getElementAutoHeight();

                if (collapse){
                    if (!init){
                        element.style.display = "none";
                        init=true;
                    } else {
                        var offset = element.offsetHeight;
                        element.style.height = offset +"px";
                        offset = element.offsetHeight;
                    }
                }
                element.style.height = newHeight +"px";
                ngElement.toggleClass('collapsed', collapse);
            });

            function getElementAutoHeight() {
                var currentHeight = getElementCurrentHeight();

                element.style.height = 'auto';
                var autoHeight = getElementCurrentHeight();

                element.style.height = currentHeight +"px";

                getElementCurrentHeight(); // Force the browser to recalc height after moving it back to normal

                return autoHeight;
            }

            function getElementCurrentHeight() {
                return element.offsetHeight;
            }
        }
    };
}]);

micronit.directive('collapsesub', [function () {
    return {
        restrict: 'A',

        link: function ($scope, ngElement, attributes) {
            var element = ngElement[0];

            $scope.$watch(attributes.collapsesub, function (collapsesub) {
                var newHeight = collapsesub ? 0 : getElementAutoHeight();
                element.style.height = newHeight +"px";
                ngElement.toggleClass('collapsedsub', collapsesub);
                ngElement.parent().parent().parent()[0].style.height="auto";
            });

            function getElementAutoHeight() {
                var currentHeight = getElementCurrentHeight();

                element.style.height = 'auto';
                var autoHeight = getElementCurrentHeight();

                element.style.height = currentHeight +"px";
                getElementCurrentHeight(); // Force the browser to recalc height after moving it back to normal

                return autoHeight;
            }

            function getElementCurrentHeight() {
                return element.offsetHeight;
            }
        }
    };
}]);


micronit.filter('truncate', function () {
    return function (text, length, end) {
        if (isNaN(length))
            length = 10;

        if (end === undefined)
            end = "...";

        if (text.length <= length || text.length - end.length <= length) {
            return text;
        }
        else {
            return String(text).substring(0, length-end.length) + end;
        }

    };
});

micronit.filter('trustThisUrl', ['$sce', function($sce) {
    return function(val) {
        return $sce.trustAsResourceUrl(val);
    };
}]);

micronit.directive('fileUpload', function () {
    return {
        scope: true,
        link: function (scope, el, attrs) {
            el.bind('change', function (event) {
                var files = event.target.files;
                for (var i = 0;i<files.length;i++) {
                    scope.$emit("fileSelected", { file: files[i] });
                }
            });
        }
    };
});


micronit.directive('clickAnywhereButHere', ['$document', function ($document) {
    return {
        link: function postLink(scope, element, attrs) {
            var onClick = function (event) {
                console.log(event);
                var isChild = $(element).has(event.target).length > 0;
                var isSelf = element[0] === event.target;
                var isInside = isChild || isSelf;
                if (!isInside) {
                    scope.$apply(attrs.clickAnywhereButHere);
                }
            };
            scope.$watch(attrs.isActive, function(newValue, oldValue) {
                if (newValue !== oldValue && newValue === true) {
                    $document.bind('click', onClick);
                }
                else if (newValue !== oldValue && newValue === false) {
                    $document.unbind('click', onClick);
                }
            });
        }
    };
}]);


micronit.factory(
    "transformRequestAsFormPost",
    function() {
        // I prepare the request data for the form post.
        function transformRequest( data, getHeaders ) {
            var headers = getHeaders();
            headers[ "Content-type" ] = "application/x-www-form-urlencoded; charset=utf-8";
            return( serializeData( data ) );
        }
        // Return the factory value.
        return( transformRequest );
        // ---
        // PRVIATE METHODS.
        // ---
        // I serialize the given Object into a key-value pair string. This
        // method expects an object and will default to the toString() method.
        // --
        // NOTE: This is an atered version of the jQuery.param() method which
        // will serialize a data collection for Form posting.
        // --
        // https://github.com/jquery/jquery/blob/master/src/serialize.js#L45
        function serializeData( data ) {
            // If this is not an object, defer to native stringification.
            if ( ! angular.isObject( data ) ) {
                return( ( data === null ) ? "" : data.toString() );
            }
            var buffer = [];
            // Serialize each key in the object.
            for ( var name in data ) {
                if ( ! data.hasOwnProperty( name ) ) {
                    continue;
                }
                var value = data[ name ];
                buffer.push(
                    encodeURIComponent( name ) +
                    "=" +
                    encodeURIComponent( ( value === null ) ? "" : value )
                );
            }
            // Serialize the buffer and clean it up for transportation.
            var source = buffer
                .join( "&" )
                .replace( /%20/g, "+" )
            ;
            return( source );
        }
    }
);

micronit.value(
    "$sanitize",
    function( html ) {
        return( html );
    }
);