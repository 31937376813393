micronit.controller('NewsletterCtrl', ['$scope', 'ngDialog', '$window', '$http', '$sce', function($scope, ngDialog, $window, $http, $sce) {
    $scope.pending = false;
    $scope.newsletterFormData = {
        EMAIL: '',
        FNAME: '',
        LNAME: ''
    };
    $scope.message = "";

    $scope.openNewsLetterSubscriptionForm = function(){
        ngDialog.open({
            template: 'subscribeMailChimp.html',
            className: 'ngdialog-theme-default',
            scope: $scope
        });

    };

    $scope.subscribeMailChimp = function(data){
        $scope.pending = true;

        $.ajax({
            type: 'POST',
            url: '//micronit.us2.list-manage.com/subscribe/post-json?u=4c2e03ebbb078fe0c30e31fae&id=f8ed1ab4b2&c=?',
            data: data,
            cache: false,
            dataType: 'jsonp',
            success: function(data, text, xhr){
                $scope.pending = false;
                $scope.message =  $sce.trustAsHtml(data.msg);
                ngDialog.closeAll();
                ngDialog.open({
                    template: 'newsletterSuccess.html',
                    className: 'ngdialog-theme-default',
                    scope: $scope,
                    preCloseCallback: function() {
                        $scope.newsletterFormData = {
                            MERGE0: '',
                            MERGE1: '',
                            MERGE2: '',
                            MERGE5: '',
                            'group[50613][1]': '',
                            'group[50621][4]': ''
                        };
                        $scope.message = "";
                    }
                });
            },
            error: function (xhr, text, error) {
                $scope.pending = false;
                $scope.message = "Something went wrong, please try again";
                ngDialog.open({
                    template: 'newsletterSuccess.html',
                    className: 'ngdialog-theme-default',
                    scope: $scope,
                    preCloseCallback: function() {
                        $scope.newsletterFormData = {
                            EMAIL: '',
                            FNAME: '',
                            LNAME: ''
                        };
                        $scope.message = "";
                    }
                });
            }
        });
    };
}]);
