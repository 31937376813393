micronit.controller('GatedDownloadCtrl', ['$scope', '$http', 'ngDialog', function($scope, $http, ngDialog) {
    $scope.showSpinner = false;
    $scope.pending = false;

    $scope.form = {
        company: "",
        firstName: "",
        lastName: "",
        email: "",
        function: "",
        hp: "",
        le: "",
        gatedDownloadId: "",
        optin: "false"
    };

    $scope.submitForm = function(restUri){
        var payload = new FormData();
        payload.append("company", $scope.form.company);
        payload.append("firstName", $scope.form.firstName);
        payload.append("lastName", $scope.form.lastName);
        payload.append("email", $scope.form.email);
        payload.append("function", $scope.form.function);
        payload.append("hp", $scope.form.hp);
        payload.append("le", $scope.form.le);
        payload.append("gatedDownloadId", $scope.form.gatedDownloadId);
        payload.append("optin", $scope.form.optin);
        $scope.pending = true;

        ngDialog.open({
            template: 'gatedDownloadSuccess.html',
            className: 'ngdialog-theme-default',
            scope: $scope,
            preCloseCallback: function() {
                $scope.form.company = "";
                $scope.form.firstName = "";
                $scope.form.lastName = "";
                $scope.form.email = "";
                $scope.form.function = "";
                $scope.form.hp = "";
                $scope.form.le = "";
                $scope.form.optin = "false";
                $scope.gatedDownloadForm.$setPristine();
            }
        });


        $http({
            url: restUri,
            method: 'POST',
            data: payload,
            headers: { 'Content-Type': undefined},
            transformRequest: angular.identity
        }).then(function(success){
            $scope.pending = false;
        }).catch(function(error){
            console.log('error', error);
            $scope.pending = false;
        });
    };

}]);
